.order-report {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}

.order-report h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.order-report .datepicker-wrapper {
  margin-bottom: 20px;
}

.order-report .datepicker-wrapper:last-child {
  margin-bottom: 30px;
}

.order-report button {
  background-color: #3b5e5e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.order-report label {
  font-size: 16px;
}

.order-report button:hover {
  background-color: #3b5e5e;
}

.DatePicker__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 8px;
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}